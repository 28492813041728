<template>
  <table class="vw-leaderboard">
    <colgroup>
      <col span="1" style="width: 25%" />
      <col span="1" style="width: 54%" />
      <col span="1" style="width: 21%" />
    </colgroup>
    <thead class="leaderboard-header">
      <tr>
        <th>{{ $t('leaderboard.table.place') }}</th>
        <th>{{ $t('leaderboard.table.initials') }}</th>
        <th>{{ $t('leaderboard.table.points') }}</th>
      </tr>
    </thead>
    <tbody class="leaderboard-body">
      <tr v-for="(user, index) in leaders" :key="index" :class="{ isCurrentUser: isCurrentUser(user.place) }">
        <td class="place">{{ user.place }}</td>
        <td class="name">{{ user.username }}</td>
        <td class="points-leaderboard">{{ user.points }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'VWLeaderboard',
  components: {},
  props: {
    leaders: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  computed: {
    ...mapState(['rank']),
  },
  methods: {
    isCurrentUser(place) {
      return place === this.rank;
    },
  },
};
</script>

<style lang="scss" scoped>
.vw-leaderboard {
  width: 90%;
  font-size: 10px;
  border-collapse: collapse;
  // box-shadow: 0 2px 6px rgba($primary-black, 0.16);
  thead {
    text-align: left;
    tr {
      border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    }
    th {
      padding: 16px 14px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: #222;
    }
  }
  .leaderboard-body {
    tr {
      box-sizing: border-box;
      text-align: left;
      border-spacing: 0 15px;
      border-top: 1px solid rgba(255, 255, 255, 0.6);
      border-bottom: 1px solid rgba(255, 255, 255, 0.6);
      // font-family: $head-font-stack;
      &:first-of-type {
        border: none;
      }
      &.isNotRanked,
      &.isCurrentUser {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        color: #d9232d;
      }
      &.break-wrapper {
        height: 0;
        border: none;
        td {
          padding: 0;
        }
      }
      td {
        padding: 20px 14px;
      }
      .place {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        color: #d9232d;
      }
      .name {
        // padding-left: 20px;
        font-size: 18px;
        text-align: left;
        text-transform: uppercase;
      }
      .points-leaderboard {
        width: calc((120 / 1024) * 100%);
        font-size: 18px;
        // text-align: right;
        // @include bp-md-tablet {
        //   font-size: 2.8em;
        // }
      }
    }
  }
}
</style>
