<template>
  <div class="container">
    <h1>{{ $t('leaderboard.title') }}</h1>
    <p>{{ $t('leaderboard.caption') }}</p>
    <VWLeaderboard :leaders="leaders" />
    <div class="pagination">
      <VWPagination v-model="currentPage" :totalItems="totalRecords" :perPage="perPage" />
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import VWLeaderboard from '@/components/VWLeaderboard.vue';
import VWPagination from '@/components/VWPagination.vue';
import { ApiService } from '@/services/api.service';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'Leaderboard',
  components: {
    VWLeaderboard,
    VWPagination,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 5,
      leaders: [],
      currentUser: null,
      totalRecords: 10,
    };
  },
  computed: {
    startIndex() {
      return (this.currentPage - 1) * this.perPage;
    },
    endIndex() {
      return this.startIndex + this.perPage;
    },
    ...mapState(['rank', 'username', 'points']),
  },
  mounted() {
    this.refreshPoints();
    this.getLeaderboardPage();
  },
  watch: {
    currentPage() {
      this.getLeaderboardPage();
    },
  },
  methods: {
    async getLeaderboardPage() {
      const { data } = await ApiService.get('/points/leaderboard', { offset: this.startIndex });
      this.leaders = data.leaderboard;
      this.totalRecords = Math.min(data.total, 10);
      this.currentUser = {
        place: this.rank,
        username: this.username,
        points: this.points,
      };
    },
    ...mapActions(['refreshPoints']),
  },
};
</script>

<style lang="scss" scoped>
.container {
  h1 {
    margin-bottom: 0;
  }
  p {
    margin-top: 26px;
    margin-bottom: 22px;
  }
}
.logo {
  display: block;
  width: 2.5em;
  height: 1.2em;
  margin-top: 2em;
  margin-right: auto;
  margin-left: auto;
}
.pagination {
  margin-top: 1em;
}
::v-deep .pagination-button {
  font-family: inherit;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #222;
}
::v-deep .vw-pagination {
  color: #222;
}
::v-deep .vw-pagination .active .pagination-button {
  color: #d9232d;
}
</style>
